import './HeroName.css'
const HeroName = ({ firstName, lastName, subText, subSubText }) => {
    return <section className="hero-section">
        <h1 style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <b className='light-text'>{firstName}</b>
            <b>{lastName}</b>
        </h1>
            <span>
                <b className='subtext'>{subText}</b>
                <b className='subtext light-text'>{subSubText}</b>
            </span>
        <div className={'hero-underline'}>

        </div>
    </section>
}
export default HeroName