import SkillsCard from "../SkillsCard/SkillsCard"
import html from '../../res/HTML.png'
import css from '../../res/CSS.png'
import javascript from '../../res/JavaScript.png'
import react from '../../res/React.png'
import mongo from '../../res/MongoDB.png'
import node from '../../res/NodeJS.png'
// import express from '../../res/ExpressJS.png'
import java from '../../res/Java.png'
import htmlSnippet from '../../res/snippets/htmlSnippet.png'
import cssSnippet from '../../res/snippets/cssSnippet.png'
import jsSnippet from '../../res/snippets/jsSnippet.png'
import jsxSnippet from '../../res/snippets/jsxSnippet.png'
import mongoSnippet from '../../res/snippets/mongoSnippet.png'
import expressSnippet from '../../res/snippets/expressSnippet.png'
import javaSnippet from '../../res/snippets/javaSnippet.png'
// import android from '../../res/Android.png'

const SkillsSection = () => {
    const style = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',

    }
    return (<section style={{ textAlign: 'center', marginTop: '40px' }}>
        <h2 className="light-text" style={{ fontSize: '80px' }}>Skills</h2>
        <div className='skills-card-container' style={style}>
            <SkillsCard text={"HTML5"} image={html} snippet={htmlSnippet} />
            <SkillsCard text={"CSS3"} image={css} snippet={cssSnippet} />
            <SkillsCard text={"JavaScript"} image={javascript} snippet={jsSnippet} />
            <SkillsCard text={"ReactJS"} image={react} snippet={jsxSnippet}/>
            <SkillsCard text={"MongoDB"} image={mongo} snippet={mongoSnippet} />
            <SkillsCard text={"NodeJS"} image={node} snippet={expressSnippet}/>
            {/* <SkillsCard text={"ExpressJS"} image={express}/> */}
            <SkillsCard text={"Java"} image={java} snippet={javaSnippet} />
            {/* <SkillsCard text={"Android"} image={android}/> */}
        </div>


    </section>)
}
export default SkillsSection;