import "./Navbar.css";
import { RiSunFill, RiMoonFill } from "react-icons/ri";
import { Link } from "react-router-dom";


 const Navbar = ({ handleModeChange, setMode, github }) => {
  return (
    <section className="container-mode">
      <div className="mode-left white-text">
        <ul>
          {github ? (<li>
            <a href={github}>
              <span class="link-decoration">Github</span>
              <span class="icon-change">
              </span>
            </a>
          </li>) : "" }
        </ul>
      </div>
      <Link to="/">Home</Link>
      
      <button onClick={handleModeChange} className="container-icon">
        {setMode ? (
          <RiMoonFill className="mode-icon" />
        ) : (
          <RiSunFill className="mode-icon" />
        )}
      </button>
    </section>
  );
}
export default Navbar;
