import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home/index";
// import Home from "../pages/OtherElement/index";

const Main = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        {/* <Route path="/OtherElement" element={<OtherElement/>} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
