import { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Footer } from "../../components/Footer/Footer";
import HeroName from "../../components/HeroName/HeroName";
import SkillsSection from "../../components/SkillsSection/SkillsSection";
const Home = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const handleModeChange = () => {
    setIsDarkMode(!isDarkMode);
  };
  return (
    <div style={{ minHeight: '100vh', maxWidth: '100%', overflow: 'hidden' }} className={isDarkMode ? "light-mode" : "dark-mode"}>
      <Navbar github={"https://www.github.com/Ryan-Romig"} setMode={isDarkMode} handleModeChange={handleModeChange} />
      <HeroName firstName={'Ryan'} lastName={"Romig"} subText={"Full Stack"} subSubText={" Web Developer"} />
      <SkillsSection />
    </div>
  );
}
export default Home;
