import './SkillsCard.css'

const SkillsCard = ({ image, text, experience, snippet }) => {

    return (<div className='skill-card-container'>
        <div className='skills-card' >
            <img className='skills-card-image' src={image} alt={text} />
            <p className='skills-card-text'>{text}</p>
        </div>
        <div className='snippet'>
            <img className='snippet-image' src={snippet} alt={text}/>
        </div>
    </div>);

}
export default SkillsCard;